import React from 'react';
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import './style.css'
 
class ImageCarousel extends React.Component {
    state = {
      images: [
        {
          original: '/img/compressed/main-sports-massage-banner-min.jpg',
          thumbnail: '/img/main-sports-massage-banner.jpg',
          originalAlt: 'women having a sports massage'
        },
        // {
        //   original: '/img/canvey-rugby-club-fb-min.jpg',
        //   thumbnail: '/img/canvey-rugby-club-fb-min.jpg',
        //   originalAlt: 'Canvey Rugby club team photo'
        // },
        // {
        //   original: '/img/gym-1-min.jpg',
        //   thumbnail: '/img/gym-1-min.jpg',
        //   originalAlt: 'Gym photo with dumbells'
        // }
      ]
    } 
 
  render() {  
    return (
      <ImageGallery className="image-carousel"
        items={this.state.images} 
        lazyLoad={false}
        showThumbnails={false}
        showFullscreenButton={false}
        showPlayButton={false}
      />
    );
  }
 
}
export default ImageCarousel;
