import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './style.css'
 
class Cards extends React.Component {
    state = {
      items: [
        {
            quote: '',
            text: "Cannot recommend highly enough. Very professional and works absolute magic!",
            referencer: 'Lauren Fielding',
            last_updated: '2 days ago'
        },
        {
            quote: '',
            text: "Absolutely Fantastic. My Partner and I both have treatments once a month and Kelly is really a miracle worker. I've suffered for years with a bad shoulder and more recently a bad back and Kelly works her magic! Can't recommended her enough. Worth every penny!",
            referencer: 'Nikkta Mills',
            last_updated: '10 days ago'
        },
        {
            quote: '',
            // text: "I have a shoulder condition on one side, which has caused my other shoulder to compensate for. Kelly has visited me for several months now and worked very hard to loosen the knots in my shoulders/back with a variety of techniques, which has helped keep me pain free. She is always friendly and professional, has a great knowledge and often sends a follow up message to check I am okay afterwards. A great massage every time -no need to go anywhere else.",
            text: "Just had the most amazing pregnancy massage. Felt so relaxed and comfortable in my own home it was just what was needed and I will definitely be having another treatment.",
            referencer: 'Clare Lawrence',
            last_updated: '29 days ago'
        }
      ]
    } 

  render() {  
    return (
      <section className="reviews-section container">
        <Container id="reviews" className="reviews-grid" fluid="true">
        <h3 className="reviews-title text-center"> Reviews</h3>
        <Row className="client-content">
            { this.state.items.map((item)=> (
                <Col md={4} sm={12}>
                <Card className="text-center review-tile">
                    {/* <Card.Header>Quote</Card.Header> */}
                    <Card.Body>
                        <blockquote className="blockquote mb-0">
                        <p>
                            {' '}
                            {item.text}
                            {' '}
                        </p>
                        <footer className="blockquote-footer">
                            {item.referencer}
                        </footer>
                        </blockquote>
                    </Card.Body>
                    <Card.Footer className="">{item.last_updated}</Card.Footer>
                    </Card>
                </Col>
            ))}
            </Row>
        </Container>
      </section>
    );
  }
 
}
export default Cards;






