import React from 'react'
import { Container } from 'react-bootstrap';

const Terms = () => (
    <Container fluid="true" className="p-0">
        <Container>
            <div>
                <section className="">
                    <h1 className="text-center terms-heading">KSL Sports Massage – Terms and Conditions </h1>
                    <p className="text-center" style={{fontStyle: 'italic', color: 'red'}}>Please make note of our terms and conditions, with particular attention to our failure to attend and our late cancellation policy </p>
                </section>

                <section className="">
                    <h2 className="terms-heading">Appointment Duration and Cost </h2>
                    <h3>Sports Massage</h3>
                    <p className="terms-text">60 minutes - (please note first appointment requires an initial assessment that can take up to 20 mins, this time will be deducted from your treatment time. Any Taping, cupping or extra treatments will be included in your treatment time and price)</p>

                    <h3>Pregnancy Massage</h3>
                    <p className="terms-text">60 minutes - (Available from the 12th week of pregnancy) </p>
                </section>

                <section className="">
                    <h2 className="terms-heading">Payment and Charges</h2>
                    <p className="terms-text">I accept all major credit and debit cards; all payments must be made at the time of your treatment. I also accept cash payments. </p>
                </section>

                <section className="">
                    <h2 className="terms-heading">Late Cancellation/Non-Attendance</h2>
                    {/* <p className="terms-text">We offer an industry standard 24-hour cancellation policy. Failure to provide 24 hours’ cancellation notice will incur a full charge for your appointment. This payment would need to be made in full before your next appointment will be booked. I offer a free 48- and 24-hour reminder text message service.</p> */}
                    <p className="terms-text">All cancellations require at least 72 hours notice, failure to do so will result in a loss of deposit. Please be aware this also includes cancellations due to Covid 19 symptoms.</p>
                </section>

                <section className="">
                    <h2 className="terms-heading">What to wear</h2>
                    <p className="terms-text">Shorts (and a vest top for females) are mandatory to be worn whilst you are receiving treatment. If you do not have shorts (or a vest top) to wear, please advise of this before your treatment and a pair will be provided for you. </p>
                </section>


                <section className="">
                    {/* <p className="text-center" style={{ fontWeight: 'bold' }}>KSL Sports Massage is a registered member of the CThA – membership no. 15675029 </p> */}
                    <p className="text-center" style={{ fontWeight: 'bold' }}>STA number - STA2852</p>
                    <p className="text-center" style={{ fontWeight: 'bold' }}>SMA number - 25919</p>
                </section>
            </div>
        </Container>
    </Container>
   
)

export default Terms
