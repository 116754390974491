import React from "react";
import { Container, Row, Col, Image } from 'react-bootstrap';
import './style.css'

class Banner extends React.Component {
  state = {
    contentOne: {
      title: 'Previously associated with Canvey Rugby Club',
      text: 'This opportunity allowed me to provide pre and post treatment, pitch side first aid and deal with injuries as they occur. I was very honoured to be working with such a fantastic team as their Sports Massage therapist.',
      image: 'img/canvey-rugby-club-fb-min.jpg'
    },
    contentTwo: {
      title: 'Treatment clinic',
      text: "I have a treatment clinic based on Canvey Island, I’ve got all the necessary safety and protection to make sure both you and I will be safe whilst still undergoing your treatment. Please use the contact form should you require more information on treatments or rehabilitation.",
      image: 'img/IMG_4175-min.jpg'
    }, 
    contentThree: {
      title: 'iTEC sports massage therapist',
      text: 'I am a fully qualified iTEC Sports Massage Therapist, my studies were undertaken at the prestigious Richdale’s institute based at Hammersmith Hospital. I have also attended further development courses with the renowned John Gibbons at the Oxford Sports University. I am also SMA registered. I am a keen Thai boxer, runner and weight lifter, I was a competitive horse rider for over 15 years so I lots of experience in the athletic world and extensive injury knowledge. I am always looking to further my knowledge and new treatments are always be added to my portfolio.',
      image: 'img/ITEC-Logo.png'
    }
  }

  render() {
    return (
      <section className="content-section container">
        <Container id="clients" className="content-banner" fluid="true">
          <Row className="content content-one">
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 2 }} lg={{ span: 6, order: 1 }} className="content-text">
                <h3>{this.state.contentOne.title}</h3>
                <p>{this.state.contentOne.text}</p>
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 1 }} lg={{ span: 6, order: 2 }} className="content-image">
                <Image alt={this.state.contentOne.title} src={this.state.contentOne.image} fluid />
            </Col>
          </Row>
          <Row className="content content-two">
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 2 }} lg={{ span: 6, order: 1 }} className="content-image">
                <Image alt={this.state.contentTwo.title} src={this.state.contentTwo.image} fluid />
            </Col>
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 1 }} lg={{ span: 6, order: 2 }} className="content-text">
                <h3>{this.state.contentTwo.title}</h3>
                <p>{this.state.contentTwo.text}</p>
            </Col>
          </Row>
          <Row className="content content-one">
            <Col xs={{ span: 12, order: 1 }} sm={{ span: 6, order: 1 }} lg={{ span: 6, order: 1 }} className="content-text">
                <h3>{this.state.contentThree.title}</h3>
                <p>{this.state.contentThree.text}</p>
            </Col>
            <Col xs={{ span: 12, order: 2 }} sm={{ span: 6, order: 2 }} lg={{ span: 6, order: 1 }} className="content-image">
                <Image alt={this.state.contentThree.title} src={this.state.contentThree.image} fluid />
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Banner;
