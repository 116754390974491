import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'
 
class Cards extends React.Component {
    state = {
      items: [
        {
          image: 'img/compressed/man-sports-massage-min.jpg',
          title: 'Sports Massage',
          text: "Is a form of bodywork geared toward participants in athletics. It is used to help prevent injuries, to prepare the body for athletic activity and maintain it in optimal condition, and to help athletes recover from workouts and injuries. Sports Massage isn’t just for athletes is can be applied to all. Sports Massage can help with injuries and chronic pain.",
          button_text: "Book now!"
        },
        {
            image: 'img/img-deep-tissue-leg-massage-min.jpg',
            title: 'Deep Tissue Massage',
            text: "Is a massage technique that's mainly used to treat musculoskeletal issues, such as strains and sports injuries. It involves applying sustained pressure using slow, deep strokes to target the inner layers of your muscles and connective tissues.",
            button_text: "Book now!"
        },
        {
            image: 'img/compressed/kinesiology-taping-image-min.jpg',
            title: 'Kinesiology Taping',
            text: "Is a definitive rehabilitative taping technique that is designed to facilitate the body’s natural healing process while providing support and stability to muscles and joints without restricting the body’s range of motion.",
            button_text: "Book now!"
        },
        {
            image: 'img/compressed/pregancy-massage-image-min.jpg',
            // image: 'img/Beauty-On-Rose-Prenatal3.jpg',
            title: 'Pregnancy Massage',
            text: "Is therapeutic bodywork that focuses on the special needs of the mother-to-be as her body goes through the dramatic changes of pregnancy. It enhances the function of muscles and joints, improves circulation and general body tone, and relieves mental and physical fatigue.",
            button_text: "Book now!"
        }
      ]
    }

  render() {  
    return (
      <section className="products-section container">
        <Container id="services" className="products-grid" fluid="true">
        <h1 className="services-title text-center">Services</h1>
        <Row className="client-content">
            { this.state.items.map((item)=> (
                <Col lg={6} md={6} sm={12}>
                <Card className="card-style" >
                    <Card.Img alt={item.title} variant="top" src={item.image} />
                    <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Text>{item.text}</Card.Text>
                        <Button href="#contact-us" variant="primary" className="button-primary">{item.button_text}
                          {/* <AnchorLink className="nav-link nav-link-color" href="#contact-us"></AnchorLink> */}
                        </Button>
                    </Card.Body>
                </Card>
                </Col>
            ))}
            </Row>
        </Container>
      </section>
    );
  }
 
}
export default Cards;
