import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faTwitter, faInstagram } from "@fortawesome/free-brands-svg-icons";
import './style.css'

export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = { message: '', name: '', email: '' };
    this.handleChangeName = this.handleChangeName.bind(this);
    this.handleChangeEmail = this.handleChangeEmail.bind(this);
    this.handleChangeMessage = this.handleChangeMessage.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  render() {
	  return (
        <section className="contact-section container">
        <Container id="contact-us" className="reviews-grid" fluid="true">
          <h2 className="contact-title text-center">Contact us</h2>
          <Row className="contact-content">
            <Col lg={12} md={12} sm={12} className="contact-col">
              <Card className="card-style text-center" >
                {/* <Card.Img alt={item.title} variant="top" src={item.image} /> */}
                <Card.Body>
                  <Card.Title>{'Schedule your appointment here!'}</Card.Title>
                    <Button href="https://kslsportsmassage.as.me/schedule.php" variant="primary" className="button-primary">{'Book now!'}</Button>
                </Card.Body>
              </Card>
            </Col>

            <Col lg={12} md={12} sm={12} className="contact-col">
            <h3 className='text-center'>Or message us below if you have any questions</h3>
            <form className="test-mailing">
              <div className="form-label">
                <label className="label-element">
                  Name
                    <input className="contact-text-field" type="text" value={this.state.name} onChange={this.handleChangeName} placeholder="Full name"/>
                </label>
              </div>
              <div className="form-label">
                <label className="label-element">
                  Email
                    <input className="contact-text-field" type="text" value={this.state.email} onChange={this.handleChangeEmail} placeholder="Email address"/>
                </label>
              </div>
              <div className="form-text-area">
                <label className="label-element">
                  Message
                  <textarea
                    className="contact-text-field"
                    id="test-mailing"
                    name="test-mailing"
                    onChange={this.handleChangeMessage}
                    placeholder="Please enter message here..."
                    required
                    value={this.state.message}
                    style={{width: '100%', height: '150px'}}
                  />
                </label>
              </div>
              <div className="form-text-area text-center">
                <input 
                  type="button" 
                  value="Send" 
                  className="btn btn--submit contact-button-submit" 
                  onClick={this.handleSubmit} 
                />
              </div>
            </form>
            </Col>
          </Row>
          <Row>


            <Col sm={6} className="address-text">
              <div class="contact-us-address text-center">
                <h3>Canvey</h3>
                <p className="address-list-item">
                  Shellbeach Road
                </p>
                <p className="address-list-item">
                  Canvey Island
                </p>
                <p className="address-list-item">
                  Essex
                </p>
                <p className="address-list-item">
                  SS8 7NT
                </p>
              </div>
            </Col>
              
              <Col sm={6} className="address-text">
              <div class="follow-us-social text-center">
                <h3>Follow us</h3>
                <a
                  href="https://www.facebook.com/kslsportsmassage/" className="socal-icons-invert facebook social" aria-label="Follow us on Facebook">
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                  href="https://www.instagram.com/ksl_sports_massage" className="socal-icons-invert instagram social" aria-label="Follow us on Instgram">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
	  )
  }

  handleChangeName(event) {
    this.setState({name: event.target.value})
  }

  handleChangeEmail(event) {
    this.setState({email: event.target.value})
  }

  handleChangeMessage(event) {
    this.setState({message: event.target.value})
  }

  handleSubmit (event) {
    const templateId = 'template_UAtPwL4p';

    this.sendMessage(templateId, {message_html: this.state.message, from_name: this.state.name, reply_to: this.state.email})
  }

  sendMessage(templateId, variables) {
    window.emailjs.send(
      'gmail', templateId,
      variables
      ).then(res => {
          console.log('Email successfully sent!')
          alert('Your request has been submitted');
      })
      .catch(err => console.error('Oh well, you failed. Here some thoughts on the error that occured:', err))
    }
}
