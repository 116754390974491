import React from 'react'
import { Container } from 'react-bootstrap';

const Terms = () => (
    <Container fluid="true" className="p-0">
        <Container>
            <div>
                <section className="">
                    <h1 className="text-center terms-heading">COVID-19 GCMT Guidance </h1>
                </section>

                <section className="">
                    {/* <h2 className="terms-heading">Following GCMT guidelines </h2> */}
                    <p className="terms-text">Soft tissue therapists are now able to return to work but there will be some changes in how your appointment will proceed in order to continue to prevent the spread of infection. I list below some of the changes you can expect.</p>

                    <p className="terms-text">Firstly, if you are experiencing any Covid-19 symptoms, please postpone or cancel your appointment. I will be taking your temperature upon arrival and there will also be a consent form to complete regarding your exposure to Covid-19. If you are considered a high-risk client, it may not be possible to massage you at this time but feel free to discuss this with me. </p>
                    
                    <p className="terms-text">If you are a first-time client, your initial consultation will take place via telephone or videoconferencing, rather than during your appointment. 
                        <p style={{fontStyle: 'italic'}}>(Alternatively, a consultation form could be emailed for completion prior to the visit).</p> If you are an existing client there will be a short update consultation, via telephone of videoconferencing, prior to your appointment to discuss your needs and any Covid-19 issues.  All consultation and consent documents should be signed and brought with you to your appointment or emailed beforehand. Please bring you own pen and water if needed, as I can no longer supply this. If you have a face mask, please wear this but if you don’t, I will supply you with one to wear throughout your treatment (we do not know if this will be necessary yet).  It would also be helpful if you wear clothing which is easy to remove and avoid wearing jewellery.
                    </p>

                    <p className="terms-text">Please remain in your car or outside and I will collect you at your appointment time, (OR upon entering the waiting area, ensure social distancing, although furniture will have been arranged to facilitate this). Please only bring a chaperone or legal guardian if necessary.  Soft furnishings in the practice room (and reception) will be covered with couch roll and all unnecessary soft furnishings and bric-a-brac will have been removed.</p>
                    
                    <p className="terms-text">Upon arrival, you will be required to remove your shoes outside of the treatment room, sterilise your hands and enter the practice room without touching anything. There will be a container in which to put your clothes when you undress.</p>
                    
                    <p className="terms-text">The treatment room will have been thoroughly disinfected and all linen and face cradles replaced for each client.  I will be wearing personal protective equipment which includes a mask, gloves and apron.  Do not be concerned about the use of gloves, it will not affect your treatment.  (This information may change as the situation regarding use of PPE is fluid).</p>
                    
                    <p className="terms-text">The massage will continue as usual, although unnecessary chatting should be avoided to prevent droplet formation.</p>
                    
                    <p className="terms-text">When the massage is over, please leave all the linens, untouched on the couch and try not to touch anything as you leave.  Please keep your mask on until you exit the premises. It is also recommended that you register for the NHS Track and Trace app to maintain our safety.</p>
                    
                    <p className="terms-text">Payment can be made prior to the appointment via Direct Transfer or PayPal but contactless payment is available.  If you have to pay with cash, please bring the correct money and place it in the supplied envelope.</p>

                    <p className="terms-text">Please note appointments will be spaced further apart than usual to enable disinfection of the practice space, therapy table, equipment and facilities. </p>

                    <p className="terms-text">I look forward to welcoming you back to the practice.</p>
                </section>

                {/* <section className="">
                    <p className="text-center" style={{ fontWeight: 'bold' }}>KSL Sports Massage is a registered member of the CThA – membership no. 15675029 </p>
                </section> */}
            </div>
        </Container>
    </Container>
   
)

export default Terms
