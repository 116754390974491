import React from 'react';
import { Alert, Navbar, Nav } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import AnchorLink from 'react-anchor-link-smooth-scroll'

import './style.css'

class AlertBanner extends React.Component {
    state = {
        items: {
        }
    }

    render() {
        return (
            // We should allow the nav colours to be configured via strapi
            <section className="nav-section">
                <Alert  className="text-center alert-banner">
                    Following the latest government guidelines and due to being a level 4 therapist I am currently still open. Please get in touch if you require an appointment - conditions apply
                </Alert>
            </section>
        )
    }
}

export default AlertBanner;
