import React from 'react';
import { Container, Row, Col, Button, Card } from 'react-bootstrap';
// import AnchorLink from 'react-anchor-link-smooth-scroll'
import './style.css'
 
class PromoCard extends React.Component {
    state = {
      items: [
        {
          // image: 'img/compressed/man-sports-massage-min.jpg',
          title: 'Schedule your appointment here',
          button_text: "Book now!"
        }
      ]
    }

  render() {  
    return (
      <section className="products-section container">
        <Container id="services" className="products-grid" fluid="true">
        <h1 className="services-title text-center">Services</h1>
        <Row className="client-content">
            { this.state.items.map((item)=> (
                <Col lg={12} md={12} sm={12}>
                <Card className="card-style text-center" >
                    {/* <Card.Img alt={item.title} variant="top" src={item.image} /> */}
                    <Card.Body>
                        <Card.Title>{item.title}</Card.Title>
                        <Button href="https://kslsportsmassage.as.me/schedule.php" variant="primary" className="button-primary">{item.button_text}
                        </Button>
                    </Card.Body>
                </Card>
                </Col>
            ))}
            </Row>
        </Container>
      </section>
    );
  }
 
}
export default PromoCard;
