import React from 'react'
import ImageCarousel from '../components/ImageCarousel/index';
import Cards from '../components/Cards/index';
import ContentGrid from '../components/ContentGrid/index';
import EmailForm from '../components/EmailForm/index';
import Reviews from '../components/Reviews/index';
import PromoCard from '../components/PromoCard/index';

import { Container } from 'react-bootstrap';

const Home = () => (
    <Container fluid="true" className="p-0">
        <div>
            <ImageCarousel />
            <PromoCard />
            <Cards />
            <ContentGrid />
            <Reviews />
            <EmailForm />
        </div>
    </Container>

)

export default Home
